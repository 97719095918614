import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import colors from '../../Utils/colors';
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  addEdge,
  getConnectedEdges,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import Sidebar from './Sidebar';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from 'react-toastify';
import DefaultResponseNode from './Nodes/DefaultResponseNode';
import IncomingWANode from './Nodes/IncomingWANode';
import ResponseMessageNode from './Nodes/ResponseMessageNode';
import ConditionNode from './Nodes/ConditionNode';
import TimeDelayNode from './Nodes/TimeDelayNode';
import AssignToChatBot from './Nodes/AssignToChatBot';
import SendEmailNode from './Nodes/SendEmailNode';
import SendSMSNode from './Nodes/SendSMSNode';
import InvokeNewFlow from './Nodes/InvokeNewFlow';
import SendWATemplate from './Nodes/SendWATemplate';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import EditNodeModal from './Modals/EditNodeModal';
import { useSelector } from 'react-redux';
import { V2CreateFlows, V2EditFlows, V2GetFlowsByID } from '../../Services';
import BetaFeatureBanner from '../../Components/BetaFeatureBanner';
import EndFlowNode from './Nodes/EndFlowNode';
import ShopifyOrderNode from './Nodes/ShopifyOrderNode';
import { eventsend } from '../../Config/analyticsFunctions';
import FacebookLeadNode from './Nodes/FacebookLeadNode';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AssignAgentNode from './Nodes/AssignAgentNode';
import AssignGPTNode from './Nodes/AssignGPTNode';
import LlamaNode from './Nodes/LlamaNode';
import CampaignSentNode from './Nodes/CampaignSentNode';
import KylasUpdateNode from './Nodes/KylasUpdateNode';
import KylasCreateNode from './Nodes/KylasCreateNode';
import IncomingInstagramNode from './Nodes/IncomingInstagramNode';
import InstagramResponseNode from './Nodes/InstagramResponseNode';
import UpdateAttributeNode from './Nodes/UpdateAttributeNode';
import AttributeConditionNode from './Nodes/AttributeConditionNode';
import UpdateChatStatusNode from './Nodes/UpdateChatStatusNode';
import PabblyEventNode from './Nodes/PabblyEventNode';
import Helper from '../../Utils/Helper';
import ConnectorLimitorModal from './Components/ConnectorLimitorModal';
import WebhookTriggerNode from './Nodes/WebhookTriggerNode';
import SendWACatalog from './Nodes/SendWACatalog';
import WAPaymentNode from './Nodes/WAPaymentNode';
import IncomingWebhookNode from './Nodes/IncomingWebhookNode';
import WooCommerceNode from './Nodes/WooCommerceNode';
import NewContactNode from './Nodes/NewContactNode';
import UpdateShopifyAttributesNode from './Nodes/UpdateShopifyAttributesNode';
import PixelEventNode from './Nodes/PixelEventNode';
import UpdateBroadCastNode from  './Nodes/UpdateBroadCastNode'
import InstagramEventNode from './Nodes/InstagramEventNode';
import FormNode from './Nodes/FormNode';
import { KeyPressOverviewModal } from './KeyPressPreviewModal';
import AddToLabel from './Nodes/AddToLabelNode';

const initialNodes = [];
const getId = () => uuidv4();

const WorkFlow = () => {
  const reactFlowWrapper = useRef(null);
  const [flowName, setFlowName] = useState('');
  const [flowID, setFlowID] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isEditNodeModalOpen, setIsEditNodeModalOpen] = useState(false);
  const [editNodeData, setEditNodeData] = useState(null);
  const [conditionsTime, setConditionsTime] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);
  const [connectionCounter, setConnectionCounter] = useState(Helper.ConnectionCounter);
  const [initialEdgeCount, setInitialEdgeCount] = useState(0);
  const [isConnectorLimitOpen, setIsConnectorLimitOpen] = useState(false);
  const params = useParams();

  const [lastSelectedNode, setLastSelectedNode] = useState(null);
  const [removedNodes, setRemovedNodes] = useState([]);
  const [lastAddedNode, setLastAddedNode] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedEdgeId, setSelectedEdgeId] = useState(null);
  const [showKeyPressModal, setShowKeyPressModal] = useState(false);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenKeyPressModal');
    if (hasSeenModal !== 'true') {
      setShowKeyPressModal(true);
    }
    eventsend("Workflow_nodes_clicks");
  }, []);

  const handleModalHide = () => {
    setShowKeyPressModal(false);
    localStorage.setItem('hasSeenKeyPressModal', 'true');
  };

  const getPremiumPlanConnectionLimit = (planName) => {
    switch (planName) {
      case 'Basic':
        return 0;
      case 'Startup':
        return 10;
      case 'Growth':
        return 50;
      case 'Pro':
        return 250;
      case 'Enterprise':
        return Infinity;
      default:
        return 1000;
    }
  };

  const customNodeStyles = {
    border: `1px solid ${colors.darkPurple}`,
    boxShadow: `0px 3px 10px ${colors.darkPurple}`,
    borderRadius: 16,
  };

  const [history, setHistory] = useState([]);

  const getEdgeStyle = (edge) => {
    if (
      edge.source === selectedNodeId ||
      edge.target === selectedNodeId ||
      edge.id === selectedEdgeId
    ) {
      return customEdgeStyles;
    }
    return {};
  };

  const customEdgeStyles = {
    stroke: colors.darkPurple,
    strokeWidth: 2,
  };

  const useKeyPress = (targetKey) => {
    const [keyPressed, setKeyPressed] = useState(false);

    useEffect(() => {
      const downHandler = ({ key }) => key === targetKey && setKeyPressed(true);
      const upHandler = ({ key }) => key === targetKey && setKeyPressed(false);

      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);

      return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
      };
    }, [targetKey]);

    return keyPressed;
  };

  const ctrlPressed = useKeyPress('Control');
  const zPressed = useKeyPress('z');
  const yPressed = useKeyPress('y');

  const duplicateNode = useCallback(
    (nodeId) => {
      const nodeToDuplicate = nodes.find((node) => node.id === nodeId);
      if (nodeToDuplicate) {
        const newNode = {
          ...JSON.parse(JSON.stringify(nodeToDuplicate)),
          id: uuidv4(),
          position: {
            x: nodeToDuplicate.position.x + 50,
            y: nodeToDuplicate.position.y + 50,
          },
          selected: true,
        };
        setNodes((nds) => nds.map((node) => ({ ...node, selected: false })).concat(newNode));
        setLastSelectedNode(newNode.id);
        setHistory((prev) => [...prev, newNode]);
      }
    },
    [nodes]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'd') {
        event.preventDefault();
        if (lastSelectedNode) {
          duplicateNode(lastSelectedNode);
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [duplicateNode, lastSelectedNode]);
  const undo = useCallback(() => {
    if (history.length > 0) {
      const lastAction = history[history.length - 1];

      if (lastAction.type === 'delete') {
        lastAction.data.forEach(({ node, edges: deletedEdges }) => {
          setNodes((nds) => [...nds, { ...node, selected: false }]);
          setEdges((edgs) => [...edgs, ...deletedEdges]);
        });
        setRemovedNodes((prev) => [...prev, lastAction]);
      } else {
        // This is an addition action
        const nodeToRemove = nodes.find((node) => node.id === lastAction.id);
        if (nodeToRemove) {
          const connectedEdges = edges.filter(
            (edge) => edge.source === nodeToRemove.id || edge.target === nodeToRemove.id
          );
          setNodes((nds) => nds.filter((node) => node.id !== nodeToRemove.id));
          setEdges((edgs) =>
            edgs.filter(
              (edge) => edge.source !== nodeToRemove.id && edge.target !== nodeToRemove.id
            )
          );
          setRemovedNodes((prev) => [...prev, { node: nodeToRemove, edges: connectedEdges }]);
        }
      }
      setHistory((prev) => prev.slice(0, -1));
      setLastSelectedNode(null);
      setSelectedNodeId(null);
    }
  }, [history, nodes, edges]);

  const redo = useCallback(() => {
    if (removedNodes.length > 0) {
      const lastRemoved = removedNodes[removedNodes.length - 1];

      if (lastRemoved.type === 'delete') {
        lastRemoved.data.forEach(({ node }) => {
          setNodes((nds) => nds.filter((n) => n.id !== node.id));
        });
        setEdges((edgs) =>
          edgs.filter(
            (edge) => !lastRemoved.data.some((item) => item.edges.some((e) => e.id === edge.id))
          )
        );
      } else {
        // This was an addition action
        setNodes((nds) => [...nds, { ...lastRemoved.node, selected: false }]);
        setEdges((edgs) => [...edgs, ...lastRemoved.edges]);
      }

      setHistory((prev) => [...prev, lastRemoved]);
      setRemovedNodes((prev) => prev.slice(0, -1));
      setLastSelectedNode(null);
      setSelectedNodeId(null);
    }
  }, [removedNodes]);
  useEffect(() => {
    if (ctrlPressed && zPressed && !isEditNodeModalOpen) undo();
    if (ctrlPressed && yPressed && !isEditNodeModalOpen) redo();
  }, [ctrlPressed, zPressed, yPressed]);

  useEffect(() => {
    if (ctrlPressed && !isEditNodeModalOpen && (zPressed || yPressed)) {
      setNodes((nds) => nds.map((node) => ({ ...node, selected: false })));
      setLastSelectedNode(null);
      setSelectedNodeId(null);
    }
  }, [ctrlPressed, zPressed, yPressed]);

  console.log('removed node', removedNodes);
  console.log('history', history);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type} node` },
      };

      setNodes((nds) => nds.concat(newNode));
      setHistory((prev) => [...prev, newNode]);
      setRemovedNodes([]);

      if (type === 'campaign') {
        const conditionsDefault = {
          id: getId(),
          type: 'condition',
          position: {
            x: position.x + 300,
            y: position.y,
          },
          data: { label: `condition node` },
        };

        setNodes((nds) => nds.concat(conditionsDefault));
        setEdges((eds) => addEdge({ source: newNode.id, target: conditionsDefault.id }, eds));
      }
    },
    [reactFlowInstance]
  );
  useEffect(() => {
    eventsend('workflow_pageview_web');
  }, []);

  useEffect(() => {
    if (params) {
      console.log('Workflow ID received ', params.id);
      setFlowID(params.id);
      loadFlowAPICall(params.id);
    }
  }, [params]);

  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const isPremiumUser = localdata?.premium;

  const navigate = useNavigate();
  let connectionLimit;
  if (localdata?._id == '667e97a55b3096422a842159'){
    connectionLimit = 250;
  }else if (localdata?._id === '66c5a2b2b8dfa0be92acd03e') {
    connectionLimit = 1000;  
}  
  else if (localdata?.isAppsumoUser && !localdata?.addOnSubscriptionActive) {
    connectionLimit = 50;
  } else if ((localdata?.isAppsumoUser && localdata?.addOnSubscriptionActive)) {
    connectionLimit = 250;
  } else if(localdata?._id === '66f39d6be534427f7b1b8b00'){
    connectionLimit=4500;
  }
  else{
    connectionLimit = getPremiumPlanConnectionLimit(localdata?.premiumPlanName);
  }
  const hasTimePassed = (timeString) => {
    var givenTime = new Date(timeString);
    var currentTime = new Date();
    if (currentTime > givenTime) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (conditionsTime?.length > 0 && hasTimePassed(conditionsTime)) {
      setButtonDisable(true);
      toast.error('The time limit set in the conditions node has elapsed', {
        position: 'top-right',
      });
    }
  }, [conditionsTime]);

  const onNodesDelete = useCallback(
    (nodesToDelete) => {
      setIsEditNodeModalOpen(false);

      const deletedNodesWithEdges = nodesToDelete.map((node) => {
        const connectedEdges = edges.filter(
          (edge) => edge.source === node.id || edge.target === node.id
        );
        return { node, edges: connectedEdges };
      });

      setHistory((prev) => [...prev, { type: 'delete', data: deletedNodesWithEdges }]);
      setRemovedNodes([]);
      setLastSelectedNode(null);
      setSelectedNodeId(null);

      // Actually remove the nodes and edges
      setNodes((nds) => nds.filter((node) => !nodesToDelete.some((n) => n.id === node.id)));
      setEdges((edgs) =>
        edgs.filter(
          (edge) => !nodesToDelete.some((n) => n.id === edge.source || n.id === edge.target)
        )
      );
    },
    [edges, setNodes, setEdges]
  );

  const nodeTypes = useMemo(
    () => ({
      defaultResponse: DefaultResponseNode,
      incomingWA: IncomingWANode,
      shopify: ShopifyOrderNode,
      responseMessage: ResponseMessageNode,
      condition: ConditionNode,
      timeDelay: TimeDelayNode,
      assignChatbot: AssignToChatBot,
      sendEmail: SendEmailNode,
      sendSMS: SendSMSNode,
      invokeNewFlow: InvokeNewFlow,
      sendWATemplate: SendWATemplate,
      catalog: SendWACatalog,
      whatsAppPayment: WAPaymentNode,
      endNode: EndFlowNode,
      facebookLead: FacebookLeadNode,
      assignAgent: AssignAgentNode,
      addToLabel: AddToLabel,
      metaPixelNode: PixelEventNode,
      updateBroadcast:UpdateBroadCastNode,
      campaign: CampaignSentNode,
      kylasUpdate: KylasUpdateNode,
      kylasCreate: KylasCreateNode,
      instagramLead: IncomingInstagramNode,
      instagramEvent:InstagramEventNode,
      instagramAction: InstagramResponseNode,
      updateAttribute: UpdateAttributeNode,
      attributeCondition: AttributeConditionNode,
      updateChatStatus: UpdateChatStatusNode,
      pabblyEvent: PabblyEventNode,
      llama: LlamaNode,
      chatgpt: AssignGPTNode,
      webhookTrigger: WebhookTriggerNode,
      incomingWebhook: IncomingWebhookNode,
      wooWFNode: WooCommerceNode,
      contactWFNode: NewContactNode,
      updateShopifyAttributes: UpdateShopifyAttributesNode,
      formsWFNode: FormNode,
    }),
    // {incomingWA: IncomingWANode}
    []
  );

  const onConnect = useCallback((params) => {
    if (connectionCounter >= connectionLimit && localdata?._id !== '664cf714f3e3a40203785e71') {
      setIsConnectorLimitOpen(true);
    } else {
      // setConnectionCounter(connectionCounter+1);
      setEdges((eds) => addEdge(params, eds));
      // console.log("connection updated")
    }
  }, []);

  useEffect(() => {
    // console.log("edges useEffect")
    setConnectionCounter(Helper.ConnectionCounter + (edges.length - initialEdgeCount));
  }, [edges]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const createFlowAPICall = () => {
    let data = {
      name: flowName === '' ? `Untitled flow ${Math.floor(Date.now() / 1000)}` : flowName,
    };
    let token = authtoken;
    V2CreateFlows(data, token).then((res) => {
      if (res?.data) {
        console.log('create flow successfully ===> ', res);
        setFlowID(res?.data?.id);
        setFlowName(res?.data?.name);
        setTimeout(() => {
          editFlowAPICall(res?.data?.id, res?.data?.name);
        }, 500);
      } else {
        console.log('create flow failed ===> ', res);
      }
    });
  };

  const editFlowAPICall = (_id, _name) => {
    let edgeIndex = -1;
    if (edges.length > 0) {
      edgeIndex = edges.findIndex((obj) => obj.target === nodes[0].id);
    }
    let index = -1;
    if (edgeIndex !== -1 && edges.length > 1) {
      index = nodes.findIndex((obj) => obj.id === edges[edgeIndex].source);
    } else if (edgeIndex !== -1) {
      index = nodes.findIndex((obj) => obj.id === edges[edgeIndex].source);
    }
    const newArr = nodes;

    if (index !== -1) {
      const matchedObject = newArr.splice(index, 1)[0]; // Remove the object from its current index
      newArr.unshift(matchedObject); // Add it to the beginning of the array
      setNodes(newArr);
    }

    let data = {
      name: flowName ? flowName : _name,
      nodes: newArr,
      edges: edges,
      duplicate: false,
    };
    let token = authtoken;
    let id = flowID ? flowID : _id;
    // console.log("data sent to edit workflows ===> ",data);
    V2EditFlows(data, token, id).then((res) => {
      if (res?.data) {
        // console.log('edit flow successfully ===> ', res);
        toast.success('Flow saved successfully!', {});
      } else {
        //console.log('edit flow failed ===> ', res);
        toast.error(res?.message, {});
      }
    });
  };

  const loadFlowAPICall = (_id) => {
    let token = authtoken;
    V2GetFlowsByID(_id, token).then((res) => {
      if (res) {
        console.log('load flow success response ', res);
        setConditionsTime(res?.data?.campaignDateTime);
        setFlowName(res?.data?.name);
        setNodes(res?.data?.nodes);
        setEdges(res?.data?.edges);
        setInitialEdgeCount(res?.data?.edges.length);
      } else {
        console.log('load flow failure response ', res);
      }
    });
  };

  const handleNodeDeletion = useCallback(
    (nodeToDelete) => {
      onNodesDelete([nodeToDelete]);
    },
    [onNodesDelete]
  );
  const oldEdgeDelete = (nodeData) => {
    const node = nodes.filter((n) => n.id === nodeData?.id);
    const connectedEdges = getConnectedEdges(node, edges);
    const oldEdge = connectedEdges.filter((edge) => edge?.sourceHandle === 'Event_UserInit');
    setEdges((edg) => edg.filter((edge) => !oldEdge?.includes(edge)));
  };

  useEffect(() => {
    if (flowID) {
      console.log('flow id exists');
    }
  }, [flowID]);

  const onNodeClick = useCallback((event, node) => {
    console.log('node clicked ... ', node);
    eventsend('Node_workflow_builder_dropped');
    setLastSelectedNode(node.id);
    if (node.type !== 'assignChatbot') {
      setEditNodeData(node);
      setIsEditNodeModalOpen(true);
    }
  }, []);

  const onSelectionChange = useCallback(({ nodes, edges }) => {
    // Handle nodes
    if (nodes.length > 0) {
      const selectedNode = nodes[nodes.length - 1]; // Get the last selected node
      setSelectedNodeId(selectedNode.id);
      setLastSelectedNode(selectedNode.id);
    } else {
      setSelectedNodeId(null);
      setLastSelectedNode(null);
    }

    // Handle edges
    if (edges.length > 0) {
      const selectedEdge = edges[0]; // Get the first selected edge
      setSelectedEdgeId(selectedEdge.id);
    } else {
      setSelectedEdgeId(null);
    }
  }, []);

  // }

  // const WorkFlow = () => {

  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  // const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
  let prevNode = null;
  let temp = [...nodes];
  const index = temp.map((e) => e.id).indexOf(editNodeData?.id);
  if (temp[index]) {
    const matchedNode = edges.filter((item) => item.target === temp[index].id);
    if (matchedNode[0]) {
      prevNode = temp.filter((item) => matchedNode[0].source === item.id)[0];
    }
  }

  return (
    <div
      className="container-fluid px-0"
      style={{ height: '100dvh', width: '100%', backgroundColor: colors.white02 }}
    >
      <div className="d-flex flex-column justify-content-between align-items-center w-100 h-100">
        {isEditNodeModalOpen && (
          <EditNodeModal
            setIsEditNodeModalOpen={setIsEditNodeModalOpen}
            onSubmit={(data) => {
              console.log('onsubmit clicked');
              setNodes((prev) => {
                let temp = [...prev];
                const index = temp.map((e) => e.id).indexOf(editNodeData?.id);
                temp[index].data = data;
                return temp;
              });
              setIsEditNodeModalOpen(false);
            }}
            onDelete={() => {
              handleNodeDeletion(editNodeData);
              setIsEditNodeModalOpen(false);
            }}
            duplicateNodeAction={() => {
              setNodes((prev) => {
                let temp = [...prev];
                temp.push({
                  id: getId(),
                  type: editNodeData?.type,
                  position: {
                    x: editNodeData?.position?.x + 300,
                    y: editNodeData?.position?.y,
                  },
                  data: JSON.parse(JSON.stringify(editNodeData?.data)),
                });
                return temp;
              });
              setIsEditNodeModalOpen(false);
            }}
            nodeData={editNodeData}
            prevNode={prevNode}
            shopifyExist={(temp[0]?.type === 'shopify' || temp[0]?.type === 'wooWFNode') ?? false}
            oldEdgeDelete={() => oldEdgeDelete(editNodeData)}
          />
        )}

        {isConnectorLimitOpen && (
          <>
            <ConnectorLimitorModal setIsModalOpen={setIsConnectorLimitOpen} />
          </>
        )}

        {/* Header Start */}
        {/* <div className="w-100 px-4" style={{ backgroundColor: colors.white01 }}>
            <BetaFeatureBanner
              BannerText={
                'This is a beta feature. Please contact Cheerio team if you are facing any issues or email at priam@cheerio.in'
              }
            />
          </div> */}

        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{
            height: 82,
            backgroundColor: colors.white01,
            width: '100%',
            borderBottom: `1px solid ${colors.borderwhite}`,
            paddingInline: 20,
          }}
        >
          <img
            src={images.BackArrowBlack}
            style={{ height: 24, width: 24, objectFit: 'contain', cursor: 'pointer' }}
            onClick={() => {
              navigate('/creator/workflow');
            }}
          />
          <InputGroup className="" style={{ width: '50%' }}>
            <Form.Control
              type="text"
              placeholder={'Enter flow name here'}
              className="w-100 btncustom"
              style={{ borderRadius: 8, fontSize: 20, fontWeight: 600, color: colors.black }}
              // isInvalid={(renewalText.length >= 640)}
              // maxLength={640}
              value={flowName}
              onChange={(e) => {
                setFlowName(e.target.value);
              }}
            />
          </InputGroup>
          {localdata?._id !== '664cf714f3e3a40203785e71' && (
            <>
              <div
                className="d-flex flex-row justify-content-center align-items-center py-1 px-3 ms-auto me-2"
                style={{ borderRadius: 8, backgroundColor: colors.white04 }}
              >
                <p style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
                  {'Connector limit: '}
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: connectionCounter >= connectionLimit ? colors.error03 : colors.black,
                    }}
                  >
                    {connectionCounter}
                  </span>
                  {`/${connectionLimit === Infinity ? 'Unlimited' : connectionLimit} used`}
                </p>
                {connectionLimit !== Infinity && (
                  <CheerioButton
                    borderStyle={{
                      border: `1px solid ${colors.primary03}`,
                      marginInlineStart: 8,
                      paddingInline: 8,
                      paddingBlock: 4,
                      borderRadius: 8,
                    }}
                    textStyle={{ color: colors.white, fontSize: 12 }}
                    btnText={'Upgrade'}
                    backColor={colors.primary03}
                    onclick={() => {
                      navigate('/addons');
                    }}
                  />
                )}
              </div>
            </>
          )}

          <CheerioButton
            borderStyle={{
              borderColor: colors.linkblue,
              paddingInline: 24,
              paddingBlock: 6,
              border: 'none',
            }}
            textStyle={{ fontSize: 16, fontWeight: 600, color: colors.white }}
            btnText={'Save Flow'}
            backColor={colors.primary03}
            disabled={buttonDisable || !isPremiumUser ? true : false}
            onclick={() => {
              eventsend('Save_flow_workflow_builder_clicked');
              const indexofShopifyNode = nodes.findIndex((obj) => obj.type === 'shopify');
              if (flowID) {
                if (
                  indexofShopifyNode > -1 &&
                  nodes[indexofShopifyNode].data.label === 'shopify node'
                ) {
                  if (nodes[indexofShopifyNode].data.shopifyEvent) {
                    editFlowAPICall();
                  } else {
                    toast.error('Please choose an event', { position: 'top-center' });
                  }
                } else {
                  editFlowAPICall();
                }
              } else {
                if (
                  indexofShopifyNode > -1 &&
                  nodes[indexofShopifyNode].data.label === 'shopify node'
                ) {
                  if (nodes[indexofShopifyNode].data.shopifyEvent) {
                    createFlowAPICall();
                  } else {
                    toast.error('Please choose an event', { position: 'top-center' });
                  }
                } else {
                  createFlowAPICall();
                }
              }
              console.log('nodes ===> ', nodes);
              console.log('edges ===> ', edges);
            }}
          />
        </div>
        {/* Header End */}
        <div
          className="d-flex flex-row justify-content-between align-items-center w-100"
          style={{ height: 'calc( 100vh - 82px )' }}
        >
          {/* Sidebar Start */}
          <Sidebar />
          {/* <div className='' style={{height:'100%',width:140,backgroundColor:colors.white01,border:`1px solid ${colors.borderwhite}`}}></div> */}
          {/* Sidebar End */}
          {/* Workflow Start */}
          <div className="" style={{ height: '100%', flex: 1 }} ref={reactFlowWrapper}>
            <ReactFlow
              nodes={nodes.map((node) => ({
                ...node,
                style: node.id === selectedNodeId ? customNodeStyles : {},
              }))}
              edges={edges.map((edge) => ({
                ...edge,
                style: getEdgeStyle(edge),
              }))}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onSelectionChange={onSelectionChange}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              onNodeClick={onNodeClick}
              onNodesDelete={onNodesDelete}
              deleteKeyCode={['Backspace', 'Delete']}
            // fitView
            >
              <Controls />
              <MiniMap />
              <Background variant="dots" gap={12} size={1} />
            </ReactFlow>
          </div>
          {/* Workflow End */}
        </div>
      </div>
      <KeyPressOverviewModal show={showKeyPressModal} onHide={handleModalHide} />
    </div>
  );
};

export default WorkFlow;
