import { identify, Identify, init, track, setUserId } from '@amplitude/analytics-browser';

const API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;
let isInitialized = false;

const logWarning = (message) => console.warn(`[Amplitude Warning]: ${message}`);
const logError = (message, error) => console.error(`[Amplitude Error]: ${message}`, error);

if (API_KEY) {
  try {
    init(API_KEY, {});
    isInitialized = true;
  } catch (error) {
    logError('Error initializing Amplitude', error);
  }
} else {
  logWarning('Amplitude API Key is missing. Initialization skipped.');
}

export const setuserid = (id) => {
  if (id) {
    setUserId(id);
  } else {
    logWarning('Invalid User ID');
  }
};

export const eventsend = (eventTxt, eventProps = {}) => {
  if (!isInitialized) {
    logWarning('Amplitude is not initialized. Event not sent.');
    return;
  }

  if (typeof eventTxt !== 'string') {
    logWarning('Event name must be a string.');
    return;
  }

  if (typeof eventProps !== 'object') {
    logWarning('Event properties must be an object.');
    return;
  }

  try {
    track(eventTxt, eventProps);
  } catch (error) {
    logError(`Error tracking event '${eventTxt}' with properties ${JSON.stringify(eventProps)}`, error);
  }
};

export const userproperties = (key, value) => {
  if (key && value !== undefined && value !== null) {
    const identifyObj = new Identify();
    identifyObj.set(key, value);
    identify(identifyObj);
  } else {
    logWarning('Invalid user property key or value');
  }
};