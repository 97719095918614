import React, { useEffect, useState } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Tooltip } from '@mui/material';
// import Table from 'react-bootstrap/Table';
import colors from '../../../Utils/colors';
import { useSelector } from 'react-redux';
import { getAllAssistant, checkOpenAi, deleteAssistant, syncAssistants } from '../../../Services';
import images from '../../../Utils/images';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import CheerioButton from '../../../Components/CheerioButton';
import CreateAssistantsModal from './CreateAssistantsModal';
import EditAssistantModal from './EditAssistantModal';
import { toast } from 'react-toastify';
import DeleteModal from '../../../Components/Modal/DeleteModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { eventsend } from '../../../Config/analyticsFunctions';

const Assistants = ({ setSelectedMenuItem }) => {
    const [openAiDetails, setOpenAiDetails] = useState();
    const [assistantsData, setAssistantsData] = useState([]);
    const [IntegrationStatus, setIntegrationStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openCreateAssistantsModal, setOpenCreateAssistantsModal] = useState(false);
    const [editAssistantId, setEditAssistantId] = useState('');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const authtoken = useSelector((state) => state.main.auth_token);
    const localdata = useSelector((state) => state.main.localdata);

    useEffect(() => {
        setIsLoading(true);
        getOpenAiDetailsAPIcall();
    }, []);

    const getOpenAiDetailsAPIcall = () => {
        let token = authtoken;
        checkOpenAi(token).then((res) => {
            if (res?.flag) {
                setOpenAiDetails(res?.data);
                setIntegrationStatus(true);
            } else {
                setIntegrationStatus(false);
            }
            setIsLoading(false);
        }).catch((e) => {
            setIntegrationStatus(false);
        });
    };

    const DeleteAssistantAPICall = (assistantId) => {
        let token = authtoken;
        const data = {
            assistantId: assistantId
        }
        deleteAssistant(token, data).then((res) => {
            if (res?.flag) {
                toast.success("Assistant Deleted Successfully", { position: 'top-center' })
                getAllAssistantsAPIcall();
                setDeleteModalOpen(false);
            } else {

                toast.error('Error in deleting assistant', { position: 'top-center' });
            }
            setIsLoading(false);
        }).catch((e) => {
            toast.error('Error in deleting assistant', { position: 'top-center' });
        });
    }

    useEffect(() => {
        if (openAiDetails?._id) {
            getAllAssistantsAPIcall();
        }
    }, [openAiDetails]);

    const getAllAssistantsAPIcall = () => {
        let token = authtoken;
        setIsLoading(true);
        getAllAssistant(token).then((res) => {
            if (res?.flag) {
                setAssistantsData(res?.data);
            } else {
                setAssistantsData([]);
                toast.error(res?.message);
            }
            setIsLoading(false);
        });
    };

    const syncAssistantsAPIcall = () => {
        let token = authtoken;
        setIsLoading(true);
        syncAssistants(token).then((res) => {
            if (res?.flag) {
                getAllAssistantsAPIcall();
            } else {
                toast.error(res?.message);
            }
            setIsLoading(false);
        });
    };

    const columns = [
        { id: 'name', label: 'Name', align: 'left', minWidth: 100, width: 200 },
        // { id: 'Triggered', label: 'Triggered', align: 'center', minWidth: 100, width: 120 },
        { id: 'instructions', label: 'Instructions', align: 'left', minWidth: 100 },
        { id: 'tools', label: 'Tools', align: 'left', maxWidth: 100, width: 150 },
        { id: 'zction', label: 'Action', align: 'center', maxWidth: 100, width: 120 },
    ];

    return (
        <>
            <div
                className="h-100 w-100 verythinscrollnew"
                style={{
                    overflowY: 'auto',
                    backgroundColor: colors.white02,
                    border: `1px solid ${colors.borderwhite}`,
                }}
            >
                <div
                    className="d-flex flex-column justify-content-start align-items-center w-100 "
                    style={{ flex: 1 }}
                >
                    {deleteModalOpen && <DeleteModal setDeleteModalOpen={setDeleteModalOpen} deleteAction={() => { DeleteAssistantAPICall(editAssistantId) }} />}
                    {isEditModalOpen && <EditAssistantModal setIsEditAssistantModalOpen={setIsEditModalOpen} editAssistantId={editAssistantId} getAllAssistantsAPIcall={getAllAssistantsAPIcall} />}
                    {openCreateAssistantsModal && <CreateAssistantsModal setOpenCreateAssistantsModal={setOpenCreateAssistantsModal} getAllAssistantsAPIcall={getAllAssistantsAPIcall} />}
                    {isLoading ? (
                        <>
                            <div
                                className="d-flex flex-column justify-content-center align-items-center"
                                style={{ height: '70vh' }}
                            >
                                <SpinnerLoader />
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className="d-flex flex-row justify-content-between align-items-center w-100"
                                style={{ marginBlockStart: 30, marginBlockEnd: 16, paddingInline: 30 }}
                            >
                                <h3
                                    style={{
                                        // padding: 0,
                                        // margin: 0,
                                        // fontSize: 20,
                                        fontWeight: 700,
                                        color: colors.black,
                                    }}
                                >
                                    {'Assistants'}
                                </h3>
                                {IntegrationStatus && localdata._id!== "65bb8f10ce5345ef070cc069" && (
                                    <div className="d-flex flex-row">
                                        <CheerioButton
                                            borderStyle={{ marginRight: '10px' }}
                                            textStyle={{ color: colors.white }}
                                            btnText={'Create Assistant'}
                                            backColor={colors.black}
                                            onclick={() => {
                                                setOpenCreateAssistantsModal(true);
                                            }}
                                            icon={images.IPlusWhite}
                                        />
                                        <CheerioButton
                                            borderStyle={{}}
                                            textStyle={{ color: colors.black }}
                                            btnText={'Sync Assistants'}
                                            backColor={colors.white}
                                            onclick={() => {
                                                eventsend("Sync_assistant_ChatGPT_integration_page_clicked");
                                                syncAssistantsAPIcall();
                                            }}
                                            icon={images.Refresh}
                                        />
                                    </div>
                                )}
                            </div>

                            {IntegrationStatus ? (
                                <div className="table">
                                    <TableContainer sx={{ height: '100%', overflow: 'auto' }}>
                                        {/* <TableContainer sx={{ flex: 1, height: "520px", overflow: "auto" }}> */}
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth,
                                                                width: column.width,
                                                                fontSize: 16,
                                                                fontWeight: 600,
                                                                color: colors.black,
                                                                backgroundColor: colors.white04,
                                                            }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {assistantsData?.map((row, index) => (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <>
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        style={{
                                                                            minWidth: column.minWidth,
                                                                            width: column.width,
                                                                        }}
                                                                    >
                                                                        {column.id === 'name' ? (
                                                                            value
                                                                        ) : column.id === 'instructions' ? (
                                                                            <span>{value?.length > 100 ? value.slice(0, 200) + '...' : value}</span>
                                                                        ) : column.id === 'tools' ? (
                                                                            <div
                                                                                style={{
                                                                                    color: colors.black,
                                                                                    fontSize: 14,
                                                                                    display: 'flex',
                                                                                    flexWrap: 'wrap',
                                                                                }}
                                                                            >
                                                                                {value.map((v, i) => (
                                                                                    <span >
                                                                                        {v?.type}
                                                                                    </span>
                                                                                ))}
                                                                            </div>
                                                                        ) : (
                                                                            <div className="d-flex flex-row justify-content-center align-items-center">
                                                                                <Tooltip title="Edit">
                                                                                    <FiEdit2
                                                                                        style={{ cursor: 'pointer', color: colors.black }}
                                                                                        onClick={() => {
                                                                                            setEditAssistantId(row.assistantId);
                                                                                            setIsEditModalOpen(true);
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                                <Tooltip title="Delete">
                                                                                    <FiTrash2
                                                                                        style={{ cursor: 'pointer', color: colors.black, marginLeft: 10 }}
                                                                                        onClick={() => {
                                                                                            setEditAssistantId(row.assistantId);
                                                                                            setDeleteModalOpen(true);
                                                                                            eventsend("Delete_assistant_ChatGPT_integration_page_clicked")
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}

                                                                    </TableCell>
                                                                </>
                                                            );
                                                        })}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '70vh' }}>
                                    <p style={{ fontSize: 16, fontWeight: 700, color: colors.greys04 }}>
                                        {'Integrate Chat GPT to continue'}
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div >
        </>
    );
};

export default Assistants;
