import { DataGrid } from '@mui/x-data-grid';
import { ContactsDepsCols } from './variableDependencies';
import { styledSx } from '../Tags/variableDependencies';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { fetchAllContacts, filterContacts } from '../../../Services';
import { timeStampToDated } from '../Labels/variableDependencies';
import { Route, Routes } from 'react-router-dom';
import EditSidebar from '../EditSidebar';
import { useEffect, useState } from 'react';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { v4 as uuidv4 } from 'uuid';
import { trackPages } from '../../../Utils/brevo';
import { getLocaltime } from '../../../Utils/localdate'
import { options } from 'linkifyjs';
import CheerioDropDown from '../../../Components/CheerioDropDown';
import { formatTimeForDisplay, formatDateForDisplay, formatTimestampForDisplay } from '../../../Utils/formatConvertor';
const ContactsAll = ({ search, filter, setFilter, allData, setAllData, userAttributes, excepted, andCondition }) => {
  const _id = useSelector((state) => state.main._id);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const trigger = useSelector((state) => state.labelRedux.triggerState);
  const [userAttributesColumn, setUserAttributesColumn] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  // const [allData, setAllData] = useState([]);

  useEffect(() => {
    trackPages('contacts_all_contacts_page', localdata);
  }, []);

  useEffect(() => {
    if (userAttributes?.length > 0) {
      setUserAttributesColumn(() => {
        let temp = [];
        userAttributes
          .filter((item) => item?.attribute)
          .map((item) => {
            temp.push({
              // field: item?.attribute + `AAA` + uuidv4(),
              field: item?.attribute,
              headerName: item?.attribute,
              width: 200,
              align: 'center',
              headerAlign: 'center',
              attrtype: item?.type,
              options: item?.options ? item?.options : [],
              renderCell: (params) => {
                if (item?.type === 'dropdown') {
                  return (
                    <CheerioDropDown
                      options={item?.options || []}
                      initialValue={params.value}
                      isSelectable={false}
                    />
                  );
                }
                return params.value;
              }
            });
          });
           temp = temp.filter(
        (column) =>
          column.headerName.toLowerCase() !== 'name' &&
          column.headerName.toLowerCase() !== 'email' &&
          column.headerName.toLowerCase() !== 'phno'
      );
        return temp;
      });
    }
  }, [userAttributes]);


  function mergeArrays(array1, array2) {
    let mergedArray = array1.slice();
    let insertIndex = mergedArray.length;
    mergedArray.splice(insertIndex, 0, ...array2);
    return mergedArray;
  }

  useEffect(()=>{
    const savedModel = localStorage.getItem('column_visibility');
    if(savedModel){
      setColumnVisibilityModel(JSON.parse(savedModel))
    }
  },[])

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
    localStorage.setItem('column_visibility', JSON.stringify(newModel));
  }

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  let pageData = {
    user_id: localdata?._id ? localdata?._id : _id,
    page: paginationModel.page + 1,
    limit: paginationModel.pageSize,
    search: search,
    filter: filter,
    andCondition: andCondition
  };
  // const { data, isLoading, isError } = useQuery(
  //   ['contactAll', trigger, paginationModel, search],
  //   () => filterContacts(pageData, authtoken)
  // );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await filterContacts(pageData, authtoken);
        if (data?.flag) {
          setAllData(data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log('error:', error);
      }
    }
    fetchData();
  }, [search, paginationModel]);

  // useEffect(() => {
  //   setAllData(data);
  // }, [data]);
  if (isLoading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center w-100"
        style={{ flex: 1 }} >
        <SpinnerLoader></SpinnerLoader>
      </div>
    );
  }

  let row_data = [];
  allData?.data?.docs?.forEach((vls) => {
    let AttrRowObject =
      userAttributesColumn?.length > 0 &&
      userAttributesColumn.reduce((obj, item) => {
        let varName = String(item?.field).split('AAA')[0];
        let attrType = item?.attrtype;
        let options = item?.options;

        // obj[item?.field] = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';
        // obj[varName] = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';

        const attributeValue = vls?.customData?.[varName] ? vls?.customData?.[varName] : '--';

        if (attrType === 'date') {
          obj[item?.field] = attributeValue !== '--' ? formatDateForDisplay(attributeValue) : '--';
        } else if (attrType === 'time') {
          obj[item?.field] = attributeValue !== '--' ? formatTimeForDisplay(attributeValue) : '--';
        } else if (attrType === 'timestamp') {
          obj[item?.field] = attributeValue !== '--' ? formatTimestampForDisplay(attributeValue) : '--';
        } else {
          obj[item?.field] = attributeValue;
        }
        return obj;
      }, {});
    row_data.push(
      Object.keys(AttrRowObject).length > 0
        ? Object.assign(
          {},
          {
            id: vls._id,
            name: vls.name,
            addedOn: getLocaltime(vls.createdAt),
            updatedOn: getLocaltime(vls.updatedAt),
            phno: vls.mobile,
            email: vls.email,
            actions: vls,
            version: vls.version,
            visible: !excepted,
          },
          AttrRowObject
        )
        : {
          id: vls._id,
          name: vls.name,
          addedOn: getLocaltime(vls.createdAt),
          updatedOn: getLocaltime(vls.updatedAt),
          phno: vls.mobile,
          email: vls.email,
          actions: vls,
          version: vls.version,
          visible: !excepted,
        }
    );
  });
  return (
    <>
      <DataGrid
        sx={styledSx}
        columns={
          userAttributesColumn?.length > 0
            ? mergeArrays(ContactsDepsCols, userAttributesColumn)
            : ContactsDepsCols
        }
        rows={row_data}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode="server"
        rowCount={allData?.data?.totalDocs}
        initialState={{ 
          pinnedColumns: { 
            left: ['name'], 
            right: ['action'] 
          },
          columns: {
            columnVisibilityModel,
          }

        }} 
        columnVisibilityModel={columnVisibilityModel} 
        onColumnVisibilityModelChange={handleColumnVisibilityChange}
      />
      <Routes>
        <Route path={'/edit'} element={<EditSidebar />} />
      </Routes>
    </>
  );
};

export default ContactsAll;
