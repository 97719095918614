import React, { useState, useRef, useEffect } from 'react';
import { Chip } from '@mui/material';
import CheerioButton from '../../Components/CheerioButton';
import { Form, InputGroup } from 'react-bootstrap';
import images from '../../Utils/images';
import { Styles } from './Styles';
import colors from '../../Utils/colors';

export const VariableBody = ({
  data,
  setData,
  Title = 'Body',
  Carousel = false,
  Bubble = false,
  textLength = 1024,
}) => {
  const { text } = data;
  const setText = (text) => setData({ ...data, text });

  const editorRef = useRef(null);

  const reOrderVariables = (value) => {
    const patternFullVariable = /\{\{+\d+\}\}+/g;
    const variablesDetected = value.match(patternFullVariable);
    let modifiedText = value;
    if (variablesDetected) {
      const orderedNumbers = variablesDetected.map((match, index) => `{{${index + 1}}}`);
      modifiedText = value.replace(/\{\{\d+\}\}/g, () => orderedNumbers.shift());
    }
    return modifiedText;
  };

  const dontResetCursor = (cursorPosition) => {
    setTimeout(() => {
      editorRef?.current?.focus();
      editorRef?.current?.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const handleBodyTextChange = (event) => {
    const { value } = event.target;
    // if key is backspace remove char before cursor
    const cursorPosition = event.target.selectionStart;
    let modifiedText = value;
    const patternFullVariable = /\{\{+\d+\}\}+/g;
    const variablesDetected = value.match(patternFullVariable);

    const updatedBodyText = variablesDetected
      ? variablesDetected.map((_, index) => {
          return data?.example?.body_text[index] ?? '';
        })
      : [];

    // if(variablesDetected){

    //   if( variablesDetected.length < data?.example?.body_text.length){
    //     // const newArr = data?.example?.body_text.filter((_,index)=>{
    //     //   !
    //     // })
    //     console.log("modifive",variablesDetected[3] ===    false);

    //   }
    // }
    if (event.nativeEvent.inputType === 'deleteContentBackward') {
      event.preventDefault();

      modifiedText = reOrderVariables(modifiedText);
      // setText(modifiedText);

      setData({
        ...data,
        text: modifiedText,
        example: {
          ...data.example,
          body_text: updatedBodyText,
        },
      });
      dontResetCursor(cursorPosition);

      return;
    } else {
      const charactersBeforeCursor = value.substring(cursorPosition - 2, cursorPosition);

      if (cursorPosition >= 2 && charactersBeforeCursor === '{{') {
        modifiedText =
          value.substring(0, cursorPosition) +
          `${!variablesDetected ? 1 : variablesDetected.length + 1}}}` +
          value.substring(cursorPosition);
      }

      modifiedText = reOrderVariables(modifiedText);
      setText(modifiedText);

      dontResetCursor(cursorPosition);
    }
  };

  const addBeforeAndAfter = (before, after) => {
    const inputValue = text;
    const textarea = editorRef.current;
    const { selectionStart, selectionEnd } = textarea;
    const textBeforeCursor = inputValue.substring(0, selectionStart);
    const textAfterCursor = inputValue.substring(selectionEnd);
    const textBetweenCursor = inputValue.substring(selectionStart, selectionEnd);
    const updatedText = `${textBeforeCursor}${before}${textBetweenCursor}${after}${textAfterCursor}`;
    setText(updatedText);
    dontResetCursor(selectionStart + 1);
  };

  const handleItalicClick = (e) => {
    e.preventDefault();
    addBeforeAndAfter('_', '_');
  };
  const handleBoldButtonClick = (e) => {
    e.preventDefault();
    addBeforeAndAfter('*', '*');
  };
  const handleStrikeThroughClick = (e) => {
    e.preventDefault();
    addBeforeAndAfter('~', '~');
  };

  const handleAddVariable = () => {
    const patternFullVariable = /\{\{+\d+\}\}+/g;
    const variablesDetected = text.match(patternFullVariable);
    setText(`${text}{{${!variablesDetected ? 1 : variablesDetected.length + 1}}}`);
  };

  return (
    <div style={{ borderBottom: '1px solid gray' }}>
      <InputGroup
        className="mb-2 px-3"
        style={{
          width: '100%',
          marginBottom: '10px',
        }}
      >
        <div style={{ border: '', width: '100%' }}>
          <Form.Label style={{ ...Styles.TxtHeading2 }}>
            {Title}
            <span
              style={{
                color: colors.error03,
                fontSize: '14px',
                marginLeft: '10px',
                fontWeight: '600',
              }}
            >{`${Carousel ? '*Mandatory' : ''}`}</span>
          </Form.Label>
          <div
            style={{
              fontSize: '14px',
              color: 'grey',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <p>Enter the text in a language which you’ve selected</p>
            <span
              style={{
                fontSize: '14px',
                color: 'grey',
                textAlign: 'end',
                justifyContent: 'right',
                marginLeft: '10px',
                marginTop: '-1px',
              }}
            >
              {`${new Blob([text]).size}/${textLength}`}
            </span>
          </div>
        </div>
        <div class="form-group" style={{ marginTop: '5px', marginBottom: '10px', width: '100%' }}>
          <textarea
            class="form-control shadow-none"
            id="editor"
            rows="5"
            ref={editorRef}
            value={text}
            onKeyDown={(e) => {
              if (Carousel == true && Bubble == false) {
                if (e.key == 'Enter') {
                  e.preventDefault();
                }
              }
            }}
            onChange={handleBodyTextChange}
            placeholder="Enter text in English"
            maxLength={textLength}
          ></textarea>
        </div>

        <div className="d-flex justify-content-end" style={{ width: '100%' }}>
          <div
            className="d-flex justify-content-evenly"
            style={{ width: '22%', height: '20px', marginRight: '10px' }}
          >
            <img
              alt="bold"
              style={{ cursor: 'pointer' }}
              src={images.Bold}
              onClick={handleBoldButtonClick}
            ></img>
            <img
              alt="italic"
              src={images.Itlaic}
              style={{ cursor: 'pointer' }}
              onClick={handleItalicClick}
            ></img>
            <img
              alt="strike"
              src={images.Strike}
              style={{ cursor: 'pointer' }}
              onClick={handleStrikeThroughClick}
            ></img>
            {/* <img src={images.Mono} style={{cursor:"pointer"}}  onClick={handleStrikeThroughClick}></img> */}
          </div>

          <CheerioButton
            className="button"
            borderStyle={{
              height: '23px',
              width: '17%',
              borderRadius: '6px',
            }}
            textStyle={{ color: 'black', fontSize: 11, fontWeight: 600 }}
            btnText={'Add variable'}
            backColor={'white'}
            onclick={handleAddVariable}
          />
        </div>

        <div>
          {text.match(/\{\{(\d+)\}\}/g)?.map((tag, index) => (
            <div className="w-100" key={index} style={{ display: 'flex', width: '500px' }}>
              <Chip label={tag} style={{ margin: '5px' }} />
              <div key={index} className="w-100">
                <Form.Control
                  type="text"
                  placeholder="Enter text"
                  className="w-100 btncustom"
                  style={{ borderRadius: 8 }}
                  value={data?.example?.body_text[index] ?? ''}
                  onChange={(e) => {
                    const { value } = e.target;
                    const updatedBodyText = [...data?.example?.body_text];
                    updatedBodyText[index] = value;
                    setData({
                      ...data,
                      example: {
                        ...data.example,
                        body_text: updatedBodyText,
                      },
                    });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </InputGroup>
    </div>
  );
};
