import { useEffect, useRef, useState } from 'react';
import '../styles.css';
import images from '../../../Utils/images';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { Comments } from './Comments';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { Select } from '@mui/material';
import {
  AddEditAttributes,
  exportRepliesChat,
  getCampaignsByPhone,
  getCampignCount,
  getClientAttributes,
  getMediaList,
  changeContactStop,
  getContactById,
  getCustomAttr,
  getCustomAttrByName
} from '../../../Services';
import { useNavigate } from 'react-router-dom';
import { MailCsvModal } from '../../CreatorAnalyticsNew/MailCsvModal';
import { getImagePath } from '../../../Utils';
import { CardImg } from 'react-bootstrap';
import { MailRepliesModal } from './MailRepliesModal';
import colors from '../../../Utils/colors';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import UserAttributesModal from './UserAttributesModal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import CheerioButton from '../../../Components/CheerioButton';
import styled from 'styled-components';
import { updateEditContact } from '../../ContactModule/contactsRedux/contactsRedux';
import {
  hiddenEmail,
  hiddenName,
  hiddenPhone,
} from '../../ContactModule/ContactsAll/variableDependencies';
import { eventsend } from '../../../Config/analyticsFunctions';
import Flag from 'react-world-flags';
import parsePhoneNumber from 'libphonenumber-js';
import {
  formatTimeForDisplay,
  formatTimestampForDisplay,
  formatDateForDisplay,
  formatTimeForInput,
  formatTimestampForInput,
  formatDateForInput,
} from '../../../Utils/formatConvertor';

const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SelectWrapper = styled(Select)`
  height: 24px;
  width: auto;
  border-radius: 12px;
  font-size: 12px;
  background-color: ${(props) => props.bgColor || 'white'};
  color: ${(props) => (props.resolvedValue === 'Unresolved' ? 'black' : 'white')};
  & .MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
    props.resolvedValue === 'Resolved'
      ? '1px solid #74D772'
      : props.resolvedValue === 'Pending'
        ? '1px solid #FFAA00'
        : '1px solid #000000'};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
    props.resolvedValue === 'Resolved'
      ? '1px solid #74D772'
      : props.resolvedValue === 'Pending'
        ? '1px solid #FFAA00'
        : '1px solid #000000'};
  }
  & .MuiSvgIcon-root {
    color: ${(props) => (props.resolvedValue === 'Unresolved' ? 'black' : 'white')};
  }
  &:hover {
    background-color: ${(props) =>
    props.resolvedValue === 'Resolved'
      ? '#74D772'
      : props.resolvedValue === 'Pending'
        ? '#FFAA00'
        : 'white'};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-block: 8px;
  flex: 1;
  overflow-y: auto;
  padding: '8px 16px';
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  border: 1px solid grey;
  border-radius: 10px;
`;

const Field = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  width: 35%;
  max-width: 100px;
  padding: 8px 16px;
  border-right: 1px solid grey;
  overflow: clip;
  text-overflow: ellipsis;
`;

const ValueField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  flex: 1;
`;

const Value = styled.p`
  font-size: 16px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const EditableInput = styled.input`
  font-size: 16px;
  word-break: break-all;
  border: none;
  outline: none;
  background: transparent;
  color: inherit;
  width: '90%';
  text-overflow: ellipsis;
`;

const AddAttributeButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  border-radius: 6px;
  border: 1px solid #000;
  cursor: pointer;
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 400;
`;

const EditableField = ({ fieldName, initialValue, activeEditing, addAttributesCheck }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [value, setValue] = useState(initialValue);
  const [currentEditing, setCurrentEditing] = useState(false);
  const [attribute, setAttribute] = useState();
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  const fetchAttr = async () => {
    const response = await getCustomAttrByName(authtoken, fieldName);
    if (response.flag) {
      setAttribute(response.data);
    }
  };


  useEffect(() => {
    if (currentEditing) {
      inputRef.current?.focus();
      fetchAttr();
    }
  }, [currentEditing]);

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (attribute?.type === 'date') {
      const dateValue = formatDateForDisplay(inputValue);
      setValue(dateValue);
    } else if (attribute?.type === 'time') {
      const timeValue = inputValue;
      setValue(timeValue);
    } else if (attribute?.type === "timestamp") {
      const timestampValue = formatTimestampForDisplay(inputValue);
      setValue(timestampValue);
    } else {
      if (attribute?.type === 'number' && isNaN(inputValue)) {
        setError('Please enter a valid number');
      } else if (attribute?.type === 'email' && !validateEmail(inputValue)) {
        setError('Please enter a valid email');
      } else if (attribute?.type === 'link' && !inputValue.includes('http')) {
        setError('Please enter a valid link');
      } else if (attribute?.type === 'website' && !inputValue.includes('http')) {
        setError('Please enter a valid website');
      } else if (attribute?.type === "text" && inputValue.length > 200) {
        setError('Maximum 200 characters allowed');
      } else if (attribute?.type === "paragraph" && inputValue.length > 2000) {
        setError('Maximum 2000 characters allowed');

      } else {
        setError('');
      }
      setValue(inputValue);
    }
  };

  useEffect(() => {
    if (attribute?.type === 'time' && value && (value.includes('AM') || value.includes('PM'))) {
      setValue(formatTimeForInput(value));
    }
  }, [value, attribute]);




  const renderInput = () => {
    if (!attribute) return <EditableInput ref={inputRef} value={value} onChange={handleChange} readOnly={!currentEditing} />;

    switch (attribute?.type) {
      case 'text':
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              ref={inputRef}
              type="text"
              value={value}
              onChange={handleChange}
              readOnly={!currentEditing}
              style={{ width: '100%', border: "none", outline: "none" }}
            />
            {error && (
              <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                {error}
              </div>)} </div>
        );
      case 'paragraph':
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              ref={inputRef}
              type="text"
              value={value}
              onChange={handleChange}
              readOnly={!currentEditing}
              style={{ width: '100%', border: "none", outline: "none" }}
            />
            {error && (
              <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                {error}
              </div>)} </div>
        );
      case 'number':
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              ref={inputRef}
              type="number"
              value={value}
              onChange={handleChange}
              readOnly={!currentEditing}
              style={{ width: '100%', border: "none", outline: "none" }}
            />
            {error && (
              <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                {error}
              </div>)}
          </div>
        );
      case 'date':
        return (
          <input
            ref={inputRef}
            type="date"
            value={currentEditing ? formatDateForInput(value) : value}
            onChange={handleChange}
            readOnly={!currentEditing}
            style={{ width: '100%', border: "none", outline: "none" }}
          />
        );
      case 'time':
        return (
          <input
            ref={inputRef}
            type="time"
            value={(value.includes('AM') || value.includes('PM')) ? formatTimeForInput(value) : value}
            onChange={handleChange}
            readOnly={!currentEditing}
            style={{ width: '100%', border: "none", outline: "none" }}
          />
        );
      case 'dropdown':
        return (
          <select
            ref={inputRef}
            value={value}
            onChange={handleChange}
            disabled={!currentEditing}
            aria-placeholder='Select an option'
            style={{
              width: "100%",
              border: "none",
              outline: "none",
              cursor: currentEditing ? "pointer" : "default",
            }}
          >
            {attribute.options.map((option, index) => (
              <option
                key={index}
                value={option.optionName}
                style={{
                  backgroundColor: option.color,
                  color: "#000",
                }}
              >
                {option.optionName}
              </option>
            ))}
          </select>
        );
      case "timestamp":
        return (
          <input
            ref={inputRef}
            type="datetime-local"
            value={formatTimestampForInput(value)}
            onChange={handleChange}
            readOnly={!currentEditing}
            style={{ width: '100%', border: "none", outline: "none" }}
          />
        );
      case "phone":
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <PhoneInput
              inputStyle={{ width: '100%', border: "none", outline: "none" }}
              country={'in'}
              countryCodeEditable={true}
              readOnly={!currentEditing}
              value={value}
              onChange={(e, d, rv, fv) => {
                let no = fv.substring(fv.indexOf(' ') + 1);
                console.log('no  ', no);
                console.log('d.dialCode  ', d.dialCode);
                const countryCode = d.dialCode;
                // const phoneNumber = countryCode + no;
                const phoneNumber = (countryCode + no).replace(/[-\s]/g, '');

                setValue(phoneNumber);
              }
              }
            />
          </div>
        );

      case "email":
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              ref={inputRef}
              type="text"
              value={value}
              onChange={handleChange}
              readOnly={!currentEditing}
              style={{ width: '100%', border: "none", outline: "none" }}
            />
            {error && (
              <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                {error}
              </div>)}
          </div>
        );

      default:
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <EditableInput
              ref={inputRef}
              value={value}
              onChange={handleChange}
              readOnly={!currentEditing}
            />
            {error && (
              <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                {error}
              </div>)} </div>
        );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: attribute?.type === "timestamp" ? '80%' : "100%",
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {renderInput()}
      {!activeEditing && !error && (
        <div
          onClick={() => {
            if (currentEditing) {
              addAttributesCheck(fieldName, value);
            }
            setCurrentEditing((prev) => !prev);
          }}
        >
          <img
            alt={currentEditing ? 'Save button' : 'Edit button'}
            src={currentEditing ? images.TickSelIcon : images.EditIcon}
            style={{ height: 24, width: 'auto', cursor: error ? 'not-allowed' : 'pointer' }}
          />
        </div>
      )}
    </div>
  );
};


const Details = ({
  excepted,
  data,
  setShow,
  resolvedValue,
  handleStatusChange,
  setSelected,
  comments,
  setComments,
  attributesObject,
  updateattributes,
  userAttributes,
  addNewAttributeAction,
  currentUser,
}) => {
  const [details, setDetails] = useState();
  const [showExportChats, setShowExportChats] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);
  const localdata = useSelector((state) => state.main.localdata);
  const [email, setEmail] = useState(localdata?.email);
  const [UAModalOpen, setUAModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletefield, setDeletefield] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [stopEnabled, setStopEnabled] = useState(false);
  const [contactDetails, setContactDetails] = useState(null);
  const waNumber = parsePhoneNumber('+' + data?.user_details?.phone);
  const [attr, setAttr] = useState([]);

  const addAttributesCheck = (attribute, value, user = data) => {
    const attrType = attr?.find((a) => a.attribute === attribute)?.type;
    if (attrType === 'date') {
      value = formatDateForInput(value);
    }
    else if (attrType === 'timestamp') {
      value = formatTimestampForInput(value);
    }

    let token = authtoken;
    let change = {};
    change[attribute] = value;
    let data = {
      clientPhone: user?.user_details?.phone,
      newChange: change,
    };
    AddEditAttributes(token, data).then((res) => {
      if (res?.flag) {
        updateattributes();
        setTimeout(() => {
          scrollToComponent(attribute);
        }, 1000);
        toast.success('Attribute successfully updated!', {
          position: 'top-right',
        });
      } else {
        toast.error('Attribute could not be updated, please try again later', {
          position: 'top-right',
        });
      }
    });
  };

  const fetchAttr = async () => {
    const response = await getCustomAttr(authtoken);
    if (response.flag) {
      const data = response.data;
      setAttr(data);
    }
  };
  // const formatTimestampForDisplay = (timestamp) => {
  //   const date = new Date(timestamp);

  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  //   const year = date.getFullYear();

  //   const hours = date.getHours();
  //   const minutes = String(date.getMinutes()).padStart(2, '0');
  //   const period = hours >= 12 ? 'PM' : 'AM';

  //   const formattedHour = hours % 12 || 12; // Convert to 12-hour format

  //   return `${day}/${month}/${year} ${formattedHour}:${minutes} ${period}`;
  // };
  const formatTimeForDisplay = (time) => {
    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours, 10);
    const suffix = hour >= 12 ? "PM" : "AM";
    const displayHour = hour % 12 || 12;
    return `${displayHour}:${minutes} ${suffix}`;
  };

  const formatValue = (key, value) => {
    console.log("key", key, value);
    const attributeType = attr.find((a) => a.attribute === key)?.type;
    console.log("attributeType", attributeType);

    if (attributeType === 'timestamp' && value) {
      return formatTimestampForDisplay(value);
    }
    if (attributeType === 'date' && value) {
      return formatDateForDisplay(value);
    }

    if (attributeType === 'time' && value) {
      return formatTimeForDisplay(value);
    }

    return value ?? '--';
  };

  const getContactDetails = () => {
    let token = authtoken;
    let sendData = {
      contactId: data?.contactId,
    };
    getContactById(token, sendData).then((res) => {
      if (res?.flag) {
        setContactDetails(res?.data);
        setStopEnabled(res?.data?.stop);
      } else {
        console.error("Cannot fetch contact details");
      }
    });
  };

  const selectedAttributeAction = (keyValue) => {
    if (attributesObject && keyValue in attributesObject) {
      toast('Attribute already exists', { position: 'top-right' });
    } else {
      addAttributesCheck(keyValue, 'NA');
    }
    setUAModalOpen(false);
  };

  const getDetails = () => {
    getCampignCount(authtoken, data?.user_details?.phone).then((res) => {
      if (res?.flag) {
        setDetails(res?.data);
      } else {
        toast.error(res?.message);
      }
    });
  };

  const exportChats = () => {
    const postData = {
      phoneNumber: data?.user_details?.phone,
      email: email,
    };
    exportRepliesChat(authtoken, postData).then((res) => {
      if (res?.flag) {
      } else {
        toast.error(res?.message);
      }
    });
  };

  useEffect(() => {
    getDetails();
    getContactDetails();
    eventsend('Contact_details_Inbox_page_clicked');
    fetchAttr();
  }, []);

  const scrollToComponent = (attr) => {
    const contentComponents = document.querySelectorAll('.content-component');
    contentComponents.forEach((component) => {
      const field = component.getElementsByClassName('field-class')[0].innerText;
      if (field === attr) {
        component.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  };

  const [activeEditing, setActiveEditing] = useState(false);

  const handleAttributeButtonClick = (fieldName) => {
    if (activeEditing) {
      setActiveEditing(false);
      return;
    }
  };

  const DeleteAttributeModal = ({ fieldName }) => {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: '100%',
            zIndex: 1000,
            top: 0,
            left: 0,
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.1)',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <div
              className="d-flex flex-column justify-content-start align-items-center"
              style={{
                width: 350,
                backgroundColor: colors.white01,
                borderRadius: 16,
                zIndex: 0,
                border: `1px solid ${colors.borderwhite}`,
                paddingBlock: 20,
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className="d-flex flex-row justify-content-between align-items-center w-100"
                style={{
                  paddingBlockEnd: 8,
                  borderBottom: `1px solid ${colors.borderwhite}`,
                  paddingInline: 16,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <img src={images.CDelete} style={{ height: 24, width: 'auto' }} />
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 18,
                      fontWeight: 700,
                      color: colors.black,
                    }}
                  >
                    {'Delete'}
                  </p>
                </div>
                <img
                  src={images.CMClose}
                  style={{
                    height: 24,
                    width: 24,
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setDeletefield('');
                    setDeleteModalOpen(false);
                  }}
                ></img>
              </div>
              <p
                style={{
                  margin: 12,
                  fontSize: 16,
                  fontWeight: 500,
                  color: colors.black,
                  marginInline: 16,
                }}
              >
                {'Do you want to delete the attribute?'}
              </p>
              <div
                className="d-flex flex-row justify-content-center align-items-center w-100"
                style={{ marginTop: 12, gap: '10px' }}
              >
                <CheerioButton
                  borderStyle={{
                    width: '40%',
                    marginInlineStart: 8,
                    border: `1px solid ${colors.primary03}`,
                  }}
                  textStyle={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: colors.primary03,
                  }}
                  btnText={'Cancel'}
                  backColor={colors.white}
                  onclick={() => {
                    console.log('cancel clicked . . .');
                    setDeletefield('');
                    setDeleteModalOpen(false);
                  }}
                />
                <CheerioButton
                  borderStyle={{
                    borderColor: colors.primary03,
                    width: '40%',
                    marginInlineEnd: 8,
                  }}
                  textStyle={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: colors.white,
                  }}
                  btnText={'Delete'}
                  backColor={'#EA2727'}
                  onclick={() => {
                    addAttributesCheck(fieldName, '--');
                    setDeletefield('');
                    setDeleteModalOpen(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    console.log('11111111111111');
    if (attributesObject) {
      console.log('222222222222222222');
    }
  }, [attributesObject]);

  // const EditableField = ({fieldName, initialValue}) => {
  //   const [value, setValue] = useState(initialValue);
  //   const [currentEditing, setCurrentEditing] = useState(false);
  //   const inputRef = useRef(null);

  //   const handleChange = (e) => {
  //     setValue(e.target.value);
  //   };

  //   useEffect(() => {
  //     console.log("33333333333")
  //     if (currentEditing && inputRef.current) {
  //       inputRef.current.focus();
  //     }
  //   }, [currentEditing]);

  //   return (
  //     <div
  //       style={{
  //         display: 'flex',
  //         width: '100%',
  //         flexDirection: 'row',
  //         alignItems: 'center',
  //         justifyContent: 'space-between',
  //       }}
  //     >
  //       <EditableInput
  //         ref={inputRef}
  //         value={value}
  //         onChange={handleChange}
  //         readOnly={!currentEditing}
  //       />
  //       {!activeEditing && (
  //         <div
  //           onClick={() => {
  //             if (currentEditing) {
  //               addAttributesCheck(fieldName, value);
  //             }
  //             setCurrentEditing((p) => !p);
  //           }}
  //         >
  //           <img
  //             alt={currentEditing ? 'Save button' : 'Edit button'}
  //             src={currentEditing ? images.TickSelIcon : images.EditIcon}
  //             style={{ height: 24, width: 'auto', cursor: 'pointer' }}
  //           />
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  return (
    <Container>
      <Header>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 48,
              height: 48,
              borderRadius: '50%',
              backgroundColor: colors.themeBlue,
              color: colors.white,
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            {data?.user_details?.name?.substring(0, 1) || 0}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '6px',
            }}
          >
            <h3
              style={{
                color: colors.black,
                fontWeight: '700',
                fontSize: '16px',
                whiteSpace: 'nowrap',
              }}
            >
              {excepted ? hiddenName(data?.user_details?.name || '') : data?.user_details?.name}
            </h3>
            <div className="d-flex" style={{ alignItems: 'center' }}>
              <Flag
                code={waNumber?.country}
                style={{ width: '20px', height: '20px', marginRight: '5px' }}
              />
              <h4 style={{ color: colors.greys04, fontSize: '14px', fontWeight: '500' }}>
                {'+' + data?.user_details?.phone}
              </h4>
            </div>
          </div>
        </div>

        {!excepted && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Tooltip title="Copy Number" style={{ border: '1px solid red' }}>
              <img
                src={images.Copy}
                height={22}
                width={22}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  eventsend('Copy_details_Side_bar_Inbox_page_clicked');
                  navigator.clipboard.writeText(
                    data?.user_details?.phone?.includes('+')
                      ? data?.user_details?.phone
                      : `+${data?.user_details?.phone}`
                  );
                  toast.success('Copied to clipboard');
                }}
              />
            </Tooltip>

            {stopEnabled ? (
              <Tooltip title="Allow Broadcast" style={{ border: '1px solid red' }}>
                <img
                  src={images.BroadcastDisabled}
                  height={30}
                  width={30}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sendData = {
                      state: false,
                      contactId: data.contactId,
                      name: currentUser
                    };
                    const token = authtoken;
                    changeContactStop(token, sendData).then((res) => {
                      if (res?.flag) {
                        setStopEnabled(false);
                        toast.success('Successfully Enabled broadcast', {
                          position: 'top-right',
                        });
                      } else {
                        toast.error('Unable to enable broadcast, please try again later', {
                          position: 'top-right',
                        });
                      }
                    });
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Disallow Broadcast" style={{ border: '1px solid red' }}>
                <img
                  src={images.BroadcastEnabled}
                  height={30}
                  width={30}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const sendData = {
                      state: true,
                      contactId: data.contactId,
                      name: currentUser
                    };
                    const token = authtoken;
                    changeContactStop(token, sendData).then((res) => {
                      if (res?.flag) {
                        setStopEnabled(true);
                        toast.success('Successfully Disabled broadcast', {
                          position: 'top-right',
                        });
                      } else {
                        toast.error('Unable to disable broadcast, please try again later', {
                          position: 'top-right',
                        });
                      }
                    });
                  }}
                />
              </Tooltip>
            )}
          </div>
        )}
      </Header>
      <ContentWrapper style={{ overflowX: 'hidden' }}>
        <Header style={{ padding: '8px 0px' }}>
          <h3 style={{ fontSize: '14px', fontWeight: 700 }}>Custom Attributes</h3>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <IconButton
              size="small"
              sx={{
                backgroundColor: colors.grey,
                borderRadius: '10px',
              }}
              onClick={() => {
                setActiveEditing((p) => !p);
              }}
            >
              <img
                alt={activeEditing ? 'save button' : 'edit button'}
                src={activeEditing ? images.TickSelIcon : images.EditIcon}
                style={{ height: 20, width: 'auto' }}
              />
            </IconButton>
            <CardImg
              className="filterToggleHover"
              src={images.PlusIcon}
              onClick={() => {
                if (userAttributes?.length > 0) {
                  setUAModalOpen(true);
                } else {
                  toast.error(
                    'Currently no other attributes can be added, please upload a csv with additional attributes.',
                    { position: 'top-right' }
                  );
                }
              }}
              style={{
                cursor: 'pointer',
                // border: '1px solid red',
                marginLeft: '5px',
                height: 30,
                width: 30,
                borderRadius: '10px',
                padding: '6px',
                backgroundColor: colors.grey,
              }}
            />
          </div>
        </Header>
        <Content style={{ position: 'relative' }}>
          {UAModalOpen && (
            <UserAttributesModal
              setUserAttributesOpen={setUAModalOpen}
              userAttributesData={userAttributes}
              selectedUserAttributeAction={selectedAttributeAction}
              positionStyle={{ top: '0%', left: '0%', width: 320 }}
              repliesSidebar
              attributesObject={attributesObject}
              addNewAttributeAction={addNewAttributeAction}
            />
          )}
          <Field>Name</Field>
          <ValueField style={{ width: '100%' }}>
            {/* <Value>
              {excepted
                ? hiddenName(data?.user_details?.name ?? '--')
                : data?.user_details?.name ?? '--'}
            </Value> */}
            <EditableField fieldName="username"
              initialValue={excepted
                ? hiddenName(data?.user_details?.name ?? '--')
                : data?.user_details?.name ?? '--'}
              activeEditing={activeEditing}
              addAttributesCheck={addAttributesCheck} />
          </ValueField>
        </Content>
        <Content>
          <Field>Phone no.</Field>
          <ValueField>
            {/* <Value>
              {data?.user_details?.phone
                ? '+' +
                (excepted ? hiddenPhone(data?.user_details?.phone) : data?.user_details?.phone)
                : '--'}
            </Value> */}
            <EditableField
              fieldName="userNumber"
              initialValue={data?.user_details?.phone
                ? '+' +
                (excepted ? hiddenPhone(data?.user_details?.phone) : data?.user_details?.phone)
                : '--'}
              activeEditing={activeEditing}
              addAttributesCheck={addAttributesCheck} />
          </ValueField>
        </Content>
        <Content>
          <Field>Email</Field>
          <ValueField>
            <EditableField
              fieldName="usermail"
              initialValue={excepted ? hiddenEmail(details?.email ?? '--') : details?.email ?? '--'}
              activeEditing={activeEditing}
              addAttributesCheck={addAttributesCheck} />
            {/* <Value>{excepted ? hiddenEmail(details?.email ?? '--') : details?.email ?? '--'}</Value> */}
          </ValueField>
        </Content>

        {attributesObject && Object.keys(attributesObject).length !== 0 ? (
          <>
            {Object.entries(attributesObject)
              .slice(
                0,
                showMore
                  ? Object.keys(attributesObject).length
                  : Math.min(2, Object.keys(attributesObject).length)
              )
              .map(([key, value]) => (
                <Content key={key}>
                  <Tooltip title={key}>
                    <Field>{key}</Field>
                  </Tooltip>
                  <Tooltip title={formatValue(key, value)}>
                    <ValueField style={{ width: '100%' }}>
                      <EditableField
                        fieldName={key}
                        initialValue={formatValue(key, value)}
                        activeEditing={activeEditing}
                        addAttributesCheck={addAttributesCheck}
                      />
                      {activeEditing && (
                        <div
                          onClick={() => {
                            setDeletefield(key);
                            setDeleteModalOpen(true);
                          }}
                        >
                          <img
                            alt={'Delete button'}
                            src={images.TrashSelIcon}
                            style={{ height: 24, width: 'auto', cursor: 'pointer' }}
                          />
                        </div>
                      )}
                      {deleteModalOpen && <DeleteAttributeModal fieldName={deletefield} />}
                    </ValueField>
                  </Tooltip>
                </Content>
              ))}
          </>
        ) : attributesObject && Object.keys(attributesObject).length === 0 ? (
          <></>
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={44}
              style={{
                marginBlockStart: 15,
                borderRadius: 10,
              }}
            />
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={44}
              style={{
                marginBlockStart: 15,
                borderRadius: 10,
              }}
            />
          </>
        )}
        {attributesObject && Object.keys(attributesObject).length > 2 && (
          <div className="d-flex flex-row justify-content-end align-items-center w-100 my-2">
            <p
              onClick={() => {
                if (!showMore) {
                  eventsend('Show_more_Side_bar_Inbox_page_clicked');
                } else {
                  eventsend('Show_less_Side_bar_Inbox_page_clicked');
                }
                setShowMore(!showMore);
              }}
              style={{ color: colors.primary03, cursor: 'pointer' }}
            >{`${showMore ? 'Show less' : 'Show more'}`}</p>
          </div>
        )}
      </ContentWrapper>
      <hr style={{ width: '100%', color: 'darkgrey', marginBlock: '8px', marginTop: '15px' }}></hr>
      <Comments data={data} comments={comments} setComments={setComments} />
      {
        showExportChats && (
          <MailRepliesModal
            show={showExportChats}
            setShow={setShowExportChats}
            mailApiCall={exportChats}
            email={email}
            setEmail={setEmail}
            reportName="Export Chats"
          />
        )
      }
    </Container >
  );
};

const Campaigns = ({ data, setSelected, excepted }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authtoken = useSelector((state) => state.main.auth_token);
  const [campaignsData, setCampaignsData] = useState([]);
  const [showMailCsv, setShowMailCsv] = useState(false);
  const [userSpecificCsv, setUserSpecificCsv] = useState(false);
  const [campaignId, setCampaignId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const campaignListApiCall = () => {
    const payLoad = {
      phoneNumber: data?.user_details?.phone,
    };
    getCampaignsByPhone(authtoken, payLoad).then((res) => {
      if (res?.flag) {
        setCampaignsData(res?.data);
        setIsLoading(false);
      } else {
        toast.error(res?.message);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    campaignListApiCall();
    eventsend('Campaign_history_Side_bar_Inbox_page_clicked');
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <SpinnerLoader></SpinnerLoader>
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex" style={{ display: 'flex', padding: '10px' }}>
        <img
          src={images.BackArrowBlack}
          onClick={() => setSelected('details')}
          style={{
            cursor: 'pointer',
            height: '22px',
            width: '22px',
            marginRight: '10px',
            marginTop: '2px',
          }}
        ></img>
        <p style={{ fontSize: '16px', fontWeight: '700' }}>Campaigns</p>
      </div>
      <hr style={{ height: '', color: 'grey', marginTop: '0px' }}></hr>
      <div
        className="verythinscrollnew"
        style={{
          overflowY: 'auto',
          marginTop: '-10px',
          height: '92vh',
        }}
      >
        {campaignsData.length > 0 &&
          campaignsData.map((campaign) => {
            return (
              <div
                className="d-flex justify-content-between"
                style={{ padding: '10px', border: '' }}
              >
                <div style={{}}>
                  <p>{campaign?.announcement?.template_name}</p>
                  <p style={{ fontSize: '12px', color: 'grey' }}>
                    {new Date(campaign?.createdAt).toLocaleString('en-US', {
                      timeZone: 'Asia/Kolkata',
                    })}
                  </p>
                </div>
                <div>
                  <img
                    onClick={() => {
                      dispatch({ type: 'addCampaignId', payload: campaign?._id });
                      // setTimeout(() => {
                      navigate('/creator/analytics');
                      // }, 3000);
                    }}
                    src={images.SNAnalytics}
                    style={{
                      cursor: 'pointer',
                      height: '28px',
                      width: '28px',
                      marginRight: '10px',
                      marginTop: '2px',
                    }}
                  ></img>
                  {!excepted && (
                    <img
                      src={images.Download_CSV}
                      onClick={() => {
                        setUserSpecificCsv(true);
                        setShowMailCsv(true);
                        setCampaignId(campaign?._id);
                      }}
                      style={{
                        cursor: 'pointer',
                        height: '28px',
                        width: '28px',
                        marginRight: '10px',
                        marginTop: '2px',
                      }}
                    ></img>
                  )}
                </div>
              </div>
            );
          })}
        {showMailCsv && (
          <MailCsvModal
            show={showMailCsv}
            setShow={setShowMailCsv}
            campaign_id={campaignId}
            channel={'WhatsApp'}
            userSpecific={userSpecificCsv}
            setUserSpecificCsv={setUserSpecificCsv}
          ></MailCsvModal>
        )}
      </div>
    </div>
  );
};

const Media = ({ data, setSelected, excepted }) => {
  const [mediaDetails, setMediaDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const authtoken = useSelector((state) => state.main.auth_token);
  const getMediaListApiCall = () => {
    getMediaList(authtoken, data?.user_details?.phone).then((res) => {
      if (res?.flag) {
        setMediaDetails(res?.data);
        setIsLoading(false);
      } else {
        toast.error(res?.message);
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    getMediaListApiCall();
    eventsend('Media_button__Side_bar_Inbox_page_clicked');
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <SpinnerLoader></SpinnerLoader>
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex" style={{ display: 'flex', padding: '10px' }}>
        <img
          src={images.BackArrowBlack}
          onClick={() => setSelected('details')}
          style={{
            cursor: 'pointer',
            height: '22px',
            width: '22px',
            marginRight: '10px',
            marginTop: '2px',
          }}
        ></img>
        <p style={{ fontSize: '16px', fontWeight: '700' }}>Media/Documents</p>
      </div>
      <hr style={{ height: '', color: 'grey', marginTop: '0px' }}></hr>
      <div
        className="verythinscrollnew"
        style={{
          overflowY: 'auto',
          marginTop: '-10px',
          height: '92vh',
        }}
      >
        {mediaDetails && mediaDetails.length > 0 ? (
          mediaDetails.map((media) => {
            return (
              <div
                className="d-flex justify-content-between"
                style={{ padding: '10px', border: '' }}
              >
                <div className="d-flex" style={{ width: '72%' }}>
                  <div>
                    {media?.type === 'document' ? (
                      // <FaFile size={40} />
                      <img
                        src={images.SBDocument}
                        style={{
                          height: 40,
                          width: 40,
                          objectFit: 'contain',
                        }}
                      ></img>
                    ) : media?.type === 'image' ? (
                      <CardImg
                        // src={he.encode(getImagePath(_msg.image))}
                        src={getImagePath(media?.image)}
                        style={{
                          height: 40,
                          width: 40,
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <img
                        src={images.SBVideo}
                        style={{
                          height: 46,
                          width: 40,
                          objectFit: 'cover',
                        }}
                      ></img>
                      // <FaFileVideo style={{ height: '40px', width: 40 }}></FaFileVideo>
                    )}
                  </div>
                  <div style={{}}>
                    <p>
                      {media?.filename === '' || !media?.filename ? 'File name' : media?.filename}
                    </p>
                    <p style={{ fontSize: '12px', color: 'grey' }}>
                      {' '}
                      {new Date(media?.timestamp).toLocaleString('en-US', {
                        timeZone: 'Asia/Kolkata',
                      })}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between" style={{ width: '25%' }}>
                  <div
                    onClick={() => window.open(media?.fileURL ?? media?.image, '_blank')}
                    style={{
                      textAlign: 'center',
                      border: '1px solid black',
                      borderRadius: '15px',
                      width: '50%',
                      fontSize: '12px',
                      fontWeight: '600',
                      height: '28px',
                      marginTop: '5px',
                      padding: '2px',
                      cursor: 'pointer',
                    }}
                  >
                    Open
                  </div>

                  <img
                    onClick={async () => {
                      const blob = await fetch(media?.fileURL ?? media?.image).then((res) =>
                        res?.blob()
                      );
                      const url = window.URL.createObjectURL(blob);
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute(
                        'download',
                        media?.filename === '' || !media?.filename ? 'Downloaded' : media?.filename
                      );
                      document.body.appendChild(link);
                      link.click();
                      toast.success('File downloaded', { position: 'top-center' });
                    }}
                    src={images.Download_CSV}
                    style={{
                      cursor: 'pointer',
                      height: '28px',
                      width: '28px',
                      marginRight: '10px',
                      marginTop: '2px',
                    }}
                  ></img>
                </div>
              </div>
            );
          })
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <p>No media found for this conversation.</p>
          </div>
        )}
      </div>
    </div>
  );
};

const AddNewAttribute = ({ data, setSelected, updateattributes }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [AttrField, setAttrField] = useState('');
  const [AttrValue, setAttrValue] = useState('');

  useEffect(() => {
    eventsend('Add_attribute_Side_bar_Inbox_page_clicked');
    eventsend('Edit_attribute_Side_bar_Inbox_page_clicked');
  }, []);

  const addAttributesCheck = (attr, value, user = data) => {
    let token = authtoken;
    let change = {};
    change[attr] = value;
    let data = {
      clientPhone: user?.user_details?.phone,
      newChange: change,
    };
    AddEditAttributes(token, data).then((res) => {
      if (res?.flag) {
        updateattributes();
        toast.success('Attribute successfully updated!', {
          position: 'top-right',
        });
        // console.log("resp succ ===> ",res);
      } else {
        toast.error('Attribute could not be updated, please try again later', {
          position: 'top-right',
        });
        // console.log("res fail ===> ",res);
      }
      setAttrField('');
      setAttrValue('');
    });
  };

  return (
    <>
      <div
        className="h-100 w-100 d-flex flex-column justify-content-start align-items-center"
        style={{ overflowY: 'auto', paddingInline: 24, paddingBlock: 20 }}
      >
        <div className="d-flex flex-row justify-content-start align-items-center w-100">
          <img
            src={images.CMClose}
            style={{
              height: 24,
              width: 24,
              objectFit: 'contain',
              cursor: 'pointer',
              marginInlineEnd: 16,
            }}
            onClick={() => setSelected('details')}
          ></img>
          <p style={{ fontSize: 16, fontWeight: 700 }}>{'Add new custom attribute'}</p>
        </div>
        <div className="py-4 w-100">
          <p style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
            {
              'Only alphanumeric characters (letter A-Z, numbers 0-9) and underscores are allowed. You cannot use reserved field names.'
            }
          </p>
        </div>
        <InputGroup className="mt-3 mb-2 w-100">
          <Form.Label style={{ fontSize: 16, fontWeight: 600 }}>{'Field Name'}</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter field name"
            className="w-100 btncustom"
            style={{ borderRadius: 8 }}
            value={AttrField}
            onChange={(e) => {
              setAttrField(e.target.value);
            }}
          />
        </InputGroup>
        <InputGroup className="mb-3 w-100">
          <Form.Label style={{ fontSize: 16, fontWeight: 600 }}>{'Field Value'}</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter field value"
            className="w-100 btncustom"
            style={{ borderRadius: 8 }}
            value={AttrValue}
            onChange={(e) => {
              setAttrValue(e.target.value);
            }}
          />
        </InputGroup>
        <div className="d-flex flex-row justify-content-around align-items-center w-100 mt-4">
          <CheerioButton
            borderStyle={{ border: `1px solid ${colors.black}`, width: '40%' }}
            textStyle={{}}
            btnText={'Cancel'}
            backColor={colors.white}
            onclick={() => setSelected('details')}
          />
          <CheerioButton
            borderStyle={{ border: `1px solid ${colors.primary03}`, width: '40%' }}
            textStyle={{ color: colors.white }}
            btnText={'Create Field'}
            backColor={colors.primary03}
            disabled={AttrField?.length > 0 && AttrValue?.length > 0 ? false : true}
            onclick={() => addAttributesCheck(AttrField, AttrValue)}
          />
        </div>
      </div>
    </>
  );
};

export const RepliesSideBar = ({
  data,
  excepted,
  setShow,
  resolvedValue,
  handleStatusChange,
  comments,
  setComments,
  attributes,
  updateattributes,
  selected,
  setSelected,
  currentUser,
}) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [userAttributes, setUserAttributes] = useState();
  const MobileWidth = window.innerWidth < 900 ? true : false;

  const filteredAttributes = { ...attributes };
  delete filteredAttributes.name;
  delete filteredAttributes.email;
  delete filteredAttributes.mobile;

  useEffect(() => {
    getUserAttributesAPICall();
  }, []);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setUserAttributes(res?.data);
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  return (
    <div
      style={{
        width: MobileWidth ? '100%' : '360px',
        boxShadow: '-5px 0px 9px 0px rgba(0, 0, 0, 0.25);',
      }}
    >
      {selected === 'details' && (
        <Details
          data={data}
          excepted={excepted}
          setShow={setShow}
          resolvedValue={resolvedValue}
          handleStatusChange={handleStatusChange}
          setSelected={setSelected}
          comments={comments}
          setComments={setComments}
          attributesObject={filteredAttributes}
          updateattributes={updateattributes}
          userAttributes={userAttributes}
          addNewAttributeAction={() => setSelected('newAttribute')}
          currentUser={currentUser}
        ></Details>
      )}
      {selected === 'campaigns' && (
        <Campaigns data={data} setSelected={setSelected} excepted={excepted}></Campaigns>
      )}{' '}
      {selected === 'media' && (
        <Media data={data} setSelected={setSelected} excepted={excepted}></Media>
      )}{' '}
      {selected === 'newAttribute' && (
        <AddNewAttribute
          data={data}
          setSelected={setSelected}
          updateattributes={updateattributes}
        />
      )}
    </div>
  );
};
