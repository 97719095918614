/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

interface Option {
    optionName: string;
    color: string;
    position: string;
}

interface StatusDropdownProps {
    options: Option[];
    initialValue: string;
    isSelectable?: boolean;
    setValue?: (value: string) => void;
}

const CheerioDropDown: React.FC<StatusDropdownProps> = ({
    options,
    initialValue,
    isSelectable = true,
    setValue = () => "",
}) => {
    const [selectedValue, setSelectedValue] = useState<string>(initialValue);

    useEffect(() => {
        const initialOption = options?.find((opt) => opt?.optionName === initialValue);

        if (initialOption) {
            document.documentElement.style.setProperty('--dropdown-bg', initialOption.color);
        }
    }, [initialValue, options]);
    console.log('options', initialValue);

    const handleChange = (event: SelectChangeEvent<string>) => {
        if (isSelectable) {
            const newValue = event.target.value as string;
            setSelectedValue(newValue);
            const selectedOption = options.find((opt) => opt.optionName === newValue);
            if (selectedOption) {
                document.documentElement.style.setProperty('--dropdown-bg', selectedOption.color);
            }
            setValue(newValue);
        }
    };

    const selectedOption = options?.find((opt) => opt?.optionName === selectedValue);

    return (
        <>
            {(!initialValue || (initialValue !== "--" && initialValue !== "NA"))
                ? <FormControl
                    sx={{
                        minWidth: 180,
                        borderRadius: '16px',
                        backgroundColor: selectedOption?.color || 'white',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '16px',
                        },
                    }}
                // disabled={!isSelectable}
                >
                    <Select
                        value={selectedValue}
                        onChange={handleChange}
                        displayEmpty
                        sx={{
                            height: '40px',
                            color: 'black',
                            borderRadius: '8px',
                            border: 'none',
                            outline: 'none',
                            backgroundColor: selectedOption?.color || 'white',
                        }}
                    >
                        {options?.map((option) => (
                            <MenuItem
                                key={option.optionName}
                                value={option.optionName}
                                sx={{
                                    height: '40px',
                                    color: 'black',
                                    borderRadius: '16px',
                                    margin: '10px',
                                    backgroundColor: option.color,
                                    '&.Mui-selected': {
                                        backgroundColor: `${option.color} !important`,
                                        color: 'white',
                                    },
                                    '&:hover': {
                                        backgroundColor: `${option.color}AA`,
                                        color: 'white',
                                        transition: '0.2s',
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <p
                                    className="statusText"
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        margin: 0,
                                    }}
                                >
                                    {option.optionName}
                                </p>
                            </MenuItem>
                        ))}

                    </Select>
                </FormControl> : <p>{initialValue}</p>}
        </>
    );
};

export default CheerioDropDown;